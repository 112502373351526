import { Col } from "react-bootstrap";
import { FaGithub } from "react-icons/fa";


export const ProjectCard = ({ title, description, imgUrl, link, liveLink }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <FaGithub className="proj-github-icon" />
          </a>
          {liveLink && (
            <a href={liveLink} target="_blank" rel="noopener noreferrer">
              <button className="custom-button">
                Real-Time Test
              </button>
            </a>
          )}
        </div>
      </div>
    </Col>
  );
}
