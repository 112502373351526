import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";
// import Spline from "@splinetool/react-spline";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(100);
  const [index, setIndex] = useState(1);
  const toRotate = [
    "Web Developer!",
    "Web Designer!",
    "Video Editor!",
    "Freelancer!",
  ];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} md={9} xl={9}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>{`Hi, I'm Toms, `}</h1>
                  <h2>
                    <span
                      className="txt-rotate"
                      dataperiod="1000"
                      data-rotate='[ "Web Developer", "Web Designer", "UI/UX Designer" ]'
                    >
                      <span className="wrap">{text}</span>
                    </span>
                  </h2>

                  <p>
                    I am on the lookout for an opportunity as a junior developer
                    to kick-start my career and learn from experienced
                    professionals. I have a strong interest in designing and
                    coding web applications that are both visually appealing and
                    user-friendly. I find great joy in bringing online projects
                    to life. My journey into web development began in 2021 when
                    I took the initiative to build a website for a friend. I am
                    driven by my passion for continuous learning and the
                    excitement of acquiring the skills needed to achieve my
                    goals and make a meaningful contribution to the dynamic
                    world of web development.
                  </p>
                  {/* <div>
                  <Spline scene="https://prod.spline.design/6lEIjayXahWewguZ/scene.splinecode" />
                  </div> */}
                 
                </div>
                
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
