
import { Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCard2 } from "./ProjectCard2";
import React, { useState } from "react";
import projImg1 from "../assets/img/1st.png";
import projImg2 from "../assets/img/2nd.png";
import projImg3 from "../assets/img/3rd.png";
import projImg4 from "../assets/img/4th.png";
import projImg5 from "../assets/img/5th.png";
import projImg6 from "../assets/img/6th.png";
import frontend from "../assets/img/frontend.png";
import rpp from "../assets/img/rpp.png";
import workout from "../assets/img/workoutdone.png";
import todo from "../assets/img/todododone.png";
import devtf from "../assets/img/ttftf.png";
import jurd from "../assets/img/jurd.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import video1 from "../assets/videos/xmas.mp4";
import video2 from "../assets/videos/supermario.mp4";
import video3 from "../assets/videos/minilooop1.mp4";
import video4 from "../assets/videos/runcis.mp4";
import video5 from "../assets/videos/cups.mp4";
import video6 from "../assets/videos/carmusic2.mp4";
export const Projects = () => {
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showAllVideos, setShowAllVideos] = useState(false);

  const projects = [
    {
      title: "CSN APP",
      description:
"Empower Riga Municipal Police with our React.js app, facilitating quick access to traffic regulations, Google Maps for location pinpointing, and easy coordinate copying, all aimed at enhancing enforcement and ensuring road safety in Riga.",
      imgUrl: rpp,
      link: "https://github.com/TomsFreimanis/ticket-generator",
      liveLink: "https://prismatic-seahorse-e8394c.netlify.app/",
    },
    {
      title: "MERN workout-app",
      description:
        
"The MERN workout-app is a web application developed using the MERN stack, which includes MongoDB, Express.js, React, and Node.js The app allows users to create workout cards, to follow there progress. Everything is saved in MongoDB database. ",
      imgUrl: workout,
      link: "https://github.com/TomsFreimanis/Mern-workout-app/tree/master",
    },
    {
      title: "MERN todo-app",
      description:
      "The MERN todo app is a web application built using the MERN stack, comprising MongoDB, Express.js, React, and Node.js. It serves as a task management tool that enables users to create, organize, and track their to-do lists. With this app, users can add tasks, set due dates, mark tasks as completed.",
      imgUrl: todo,
      link: "https://github.com/TomsFreimanis/MERN-todo-app/tree/master",
    },
    {
      title: "Food recipes app",
      description:
        "Introducing a delightful food recipes app crafted with ReactJS and powered by an API",
      imgUrl: projImg1,
      link: "https://github.com/TomsFreimanis/project13",
    },
    {
      title: "Weather app",
      description:
        "The Weather app is a React.js application that provides real-time weather information to users",
      imgUrl: projImg2,
      link: "https://github.com/TomsFreimanis/weather",
    },
    {
      title: "NBA app",
      description:
        "The NBA app is a React.js application that allows users to check players info",
      imgUrl: projImg3,
      link: "https://github.com/TomsFreimanis/nba",
    },
    {
      title: "Frontend challenge",
      description:
        "Taks from frontend Challenge",
      imgUrl: frontend,
      liveLink: "https://guileless-pithivier-013e30.netlify.app/"
    },
  
    {
      title: "Meme app",
      description:
        "The MEME app is a React.js application that lets users create and share their own memes using random images",
      imgUrl: projImg5,
      liveLink: "https://elegant-malabi-650db1.netlify.app/",
      link: "https://github.com/TomsFreimanis/memegenerator",
    },
    {
      title: "Firs portfolio",
      description: "Using nextjs and tailwind css",
      imgUrl: devtf,
      link: "https://github.com/TomsFreimanis/potfolio-nextjs/tree/master/react-portfolio-tail",
    },
  ];

  const projects2 = [
    {
      title: "Rigasautoskola.lv",
      description:
        "The WordPress homepage dedicated to C class driver's lessons offers a comprehensive platform for individuals interested in learning how to drive C class vehicles",
      imgUrl: projImg4,
      link: "http://Rigasautoskola.lv",
    },
    {
      title: "KBsolutions",
      description:
        "The WordPress homepage I've developed for a law firm is a comprehensive platform designed to provide useful information to visitors",
      imgUrl: jurd,
      link: "https://www.kbsolutions.lv",
    },
    {
      title: "Maisinspele.lv",
      description:
        "The WordPress project I've developed is an e-commerce website that enables users to buy items online",
      imgUrl: projImg6,
      link: "https://maisinspele.lv/",
    },
  ];
  const videos = [
    {
      title: "",
      description: "Catfun",
      videoUrl: video4,
    },
    {
      title: "Video 2",
      description: "Magic cups",
      videoUrl: video5,
    },
    {
      title: "Video 2",
      description: "Music",
      videoUrl: video6,
    },
    {
      title: "Video 1",
      description: "Xmas",
      videoUrl: video1,
    },
    {
      title: "Video 2",
      description: "Mario training",
      videoUrl: video2,
    },
    {
      title: "Video 2",
      description: "Loop",
      videoUrl: video3,
    },
    // Add more video objects as needed
  ];
  const displayedProjects = showAllProjects ? projects : projects.slice(0, 3);
  const displayedVideos = showAllVideos ? videos : videos.slice(0, 3);

  const handleLoadMoreProjects = () => {
    setShowAllProjects(true);
  };

  const handleLoadMoreVideos = () => {
    setShowAllVideos(true);
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };
  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col xs={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2>Projects</h2>
                  <p>
                    Introducing my portfolio page, a showcase of my diverse
                    range of projects that reflect my passion for technology and
                    creativity. Within this collection, you'll find a
                    compilation of standout creations, each designed and
                    developed with meticulous attention to detail.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          style={{ fontSize: "14px" }} // Adjust the font size as per your requirement
                        >
                          React js
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          style={{ fontSize: "14px" }} // Adjust the font size as per your requirement
                        >
                          Wordpress
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          style={{ fontSize: "14px" }} // Adjust the font size as per your requirement
                        >
                          Videos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp">
                      <Tab.Pane eventKey="first">
                        <Row>
                          {displayedProjects.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                        {!showAllProjects && (
                          <div style={buttonStyle}>
                            <Button
                              variant="primary"
                              style={{ backgroundColor: "#66fcf1" }}
                              onClick={handleLoadMoreProjects}
                            >
                              Load More Projects
                            </Button>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects2.map((project, index) => (
                            <ProjectCard2 key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {displayedVideos.map((video, index) => (
                            <Col md={4} key={index}>
                              <div className="video-card">
                                <p>{video.description}</p>
                                <video width="100%" height="250" controls>
                                  <source
                                    src={video.videoUrl}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            </Col>
                          ))}
                        </Row>
                        {!showAllVideos && (
                          <div style={buttonStyle}>
                            <Button
                              variant="primary"
                              style={{ backgroundColor: "#66fcf1" }}
                              onClick={handleLoadMoreVideos}
                            >
                              Load More Videos
                            </Button>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
