import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import contactImg from "../assets/img/reactnew.png";
import contactImg2 from "../assets/img/coding.png";

const Test = () => {
  return (
    <section className="section-test">
      <Container
        id="Freelance"
        className="d-flex flex-column align-items-center text-center container-test"
      >
        <Row className="test-row justify-content-center align-items-center">
          <h1>Freelance</h1>
          <Col sm={6} className="test mb-3 mb-sm-0">
            <Card
              style={{
                backgroundColor: "black",
                boxShadow: "0 6px 12px 0 rgba(102, 252, 241, 0.6)",
              }}
              className="mx-auto"
            >
              <Card.Img variant="top" src={contactImg} />
              <Card.Body>
                <Card.Title style={{ color: "#66fcf1" }}>React JS</Card.Title>
                <Card.Text style={{ color: "#ffffff" }}>
                  I offer professional React JS homepage development services.
                  With my expertise, I can create stunning and responsive React
                  JS websites tailored to your needs.
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush list-items custom-list-group">
                <ListGroup.Item>Responsive design</ListGroup.Item>
                <ListGroup.Item>Optimized performance</ListGroup.Item>
                <ListGroup.Item>Customized design</ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col sm={6} className="test mb-3 mb-sm-0">
            <Card
              style={{
                backgroundColor: "black",
                boxShadow: "0 6px 12px 0 rgba(102, 252, 241, 0.6)",
              }}
              className="mx-auto"
            >
              <Card.Img variant="top" src={contactImg2} />
              <Card.Body>
                <Card.Title style={{ color: "#66fcf1" }}>
                  WordPress Websites
                </Card.Title>
                <Card.Text style={{ color: "#ffffff" }}>
                  Looking for a professionally designed WordPress website? I
                  specialize in creating eye-catching and functional WordPress
                  sites that will help you establish a strong online presence.
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush custom-list-group">
                <ListGroup.Item>Mobile responsiveness</ListGroup.Item>
                <ListGroup.Item>User-friendly interfaces</ListGroup.Item>
                <ListGroup.Item>Fast loading times</ListGroup.Item>
              </ListGroup>
            </Card>
            
          </Col>
          
        </Row>
        <Row>
          
        </Row>
      </Container>
    </section>
  );
};

export default Test;
