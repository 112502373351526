import meter1 from "../assets/img/app-development.png";
import meter2 from "../assets/img/javascript.png";
import meter3 from "../assets/img/bootstrap.png";
import meter4 from "../assets/img/wind.png";
import meter5 from "../assets/img/science.png";
import meter6 from "../assets/img/montage.png";
import Carousel from 'react-multi-carousel';
import wordpress from "../assets/img/wordpress-logo.png"
import html5 from "../assets/img/html-5.png"
import sass from "../assets/img/sass.png"
import git from "../assets/img/git.png"
import 'react-multi-carousel/lib/styles.css';
import Spline from '@splinetool/react-spline';


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1500} // Adjust the speed (in milliseconds)
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="web" />
                  <h5>Web Development</h5>
                </div>
                <div className="item">
                  <img src={meter2} alt="Js" />
                  <h5>Javascript</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="Bootstrap" />
                  <h5>Bootstrap</h5>
                </div>
                <div className="item">
                  <img src={meter4} alt="Tailwind" />
                  <h5>Tailwind css</h5>
                </div>
                <div className="item">
                  <img src={meter5} alt="React" />
                  <h5>React js</h5>
                </div>
                <div className="item">
                  <img src={meter6} alt="Video" />
                  <h5>Video editing</h5>
                </div>
                <div className="item">
                  <img src={wordpress} alt="Video" />
                  <h5>Wordpress</h5>
                </div>
                <div className="item">
                  <img src={html5} alt="Video" />
                  <h5>HTML5</h5>
                </div>
                <div className="item">
                  <img src={sass} alt="Video" />
                  <h5>Sass</h5>
                </div>
                <div className="item">
                  <img src={git} alt="Video" />
                  <h5>Git</h5>
                </div>
                <div className="item-splin">
                <Spline scene="https://prod.spline.design/DHW6W9tYE-VwAeVj/scene.splinecode" />
                  {/* <h5>Spline Design</h5> */}
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
