import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Spline from '@splinetool/react-spline';
// import contactImg from "../assets/img/remb.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from "emailjs-com";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: ""
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({ success: null, message: "" });

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    let isValid = true;
    if (!formDetails.firstName) {
      setStatus({
        success: false,
        message: "Please enter your first name."
      });
      isValid = false;
    }
    if (!formDetails.email) {
      setStatus({
        success: false,
        message: ""
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    if (!validateEmail(formDetails.email)) {
      setStatus({
        success: false,
        message: "Please enter a valid email address."
      });
      return;
    }

    setButtonText("Sending...");
    emailjs
      .send(
        "service_xfv33c7",
        "template_5wulvch",
        formDetails,
        "hJntk0x-lzFxlWvAr"
      )
      .then(
        (response) => {
          setButtonText("Send");
          setFormDetails(formInitialDetails);
          setStatus({
            success: true,
            message: "Message sent successfully!"
          });
        },
        (error) => {
          console.log(error);
          setButtonText("Send");
          setStatus({
            success: false,
            message: "Something went wrong, please try again later."
          });
        }
      );
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
          <div className="spline-container">
          <Spline scene="https://prod.spline.design/Lc1UJbpi75VTON-t/scene.splinecode" />
            </div>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__animated" : ""}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.firstName}
                          placeholder="First Name"
                          onChange={(e) => onFormUpdate("firstName", e.target.value)}
                        />
                        {status.success === false && !formDetails.firstName && (
                          <p className="error">Please enter your first name</p>
                        )}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.lastName}
                          placeholder="Last Name"
                          onChange={(e) => onFormUpdate("lastName", e.target.value)}
                        />
                        {status.success === false && !formDetails.lastName && (
                          <p className="error">Please enter your last name</p>
                        )}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="email"
                          value={formDetails.email}
                          placeholder="Email Address"
                          onChange={(e) => onFormUpdate("email", e.target.value)}
                        />
                        {status.success === false && !formDetails.email && (
                          <p className="error">Please enter your email</p>
                        )}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="tel"
                          value={formDetails.phone}
                          placeholder="Phone No."
                          onChange={(e) => onFormUpdate("phone", e.target.value)}
                        />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea
                          rows="6"
                          value={formDetails.message}
                          placeholder="Message"
                          onChange={(e) => onFormUpdate("message", e.target.value)}
                        ></textarea>
                        <button type="submit">
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                      {status.success && (
                        <Col>
                          <p className="success">{status.message}</p>
                        </Col>
                      )}
                      {status.success === false && (
                        <Col>
                          <p className="error">{status.message}</p>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
